import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { httpGet, httpPut, httpPost } from '../functions/request';
import jwtDecode from 'jwt-decode';

const AuthContext = React.createContext();

const AuthProvider = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);
  const [decodedToken, setDecodedToken] = useState(null);
  const [id, setId] = useState(null);
  const [role, setRole] = useState(null);
  const [user, setUser] = useState(null);
  const [vteAssigned, setVteAssigned] = useState(null);
  const [agency, setAgency] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);

  useEffect(() => {
    if (window.location.pathname === '/login' || window.location.pathname === '/registration' || window.location.pathname === '/recovery') return ;
    
    const accessToken = localStorage.getItem('access_token');
    
    if ((accessToken == undefined || accessToken == null) && window.location.pathname !== '/login') {
      return window.location.href = '/login';
    }
    
    const decoded = jwtDecode(accessToken, { complete: true });
    const now = new Date().getTime();

    if ((decoded.exp * 1000) <= now) {
      localStorage.removeItem('access_token');
      return window.location.href = '/login';
    }

    const fetchUserInfo = async () => {
      const user = await httpGet(`/users/me`, {}, accessToken);

      if (!user.ok) return console.error("/api/v1/users/me endpoint doesn't works");

      setUser(user.results)
      setId(user.results.id)
      setRole(user.results.role)
      setVteAssigned(user.results.vteAssigned)
      setAgency(user.results.agency)
      setFirstName(user.results.firstName)
      setLastName(user.results.lastName)
      setToken(accessToken);
      setIsAuthenticated(true);
      setDecodedToken(decoded);
    };

    fetchUserInfo();

    const setCookie = (cname, cvalue, exdays) => {
      const d = new Date();
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      let expires = "expires="+ d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;domain=.cips.it;";
    }

    setCookie("_carauth", accessToken, decoded.exp);
  }, [isAuthenticated, token]);

  const login = async (username, password) => {
    const result = await httpPost('/auth/login', { username, password });

    if (!result.ok) return false;
  
    localStorage.setItem('access_token', result.results.access_token);
    setIsAuthenticated(true);
    setToken(result.results.access_token);
    
    // set cookie for check the access on the private blog (.cips.it)

    return true;
  }

  const signup = async (body) => {
    const result = await httpPost('/users/create', body);
    if (!result.ok) return [false, result.error];
    return [true, "Il tuo account verrà attivato entro 48 ore lavorative."];
  }

  const update = async (body) => {
    const result = await httpPut(`/users/single/client/${id}`, body, token);
    if (!result.ok) return [false, result.error];
    return [true, "Account aggiornato con successo."];
  }

  const logout = async () => {
    localStorage.removeItem('access_token');
    window.location.href = '/login';
  }

  return (
    <AuthContext.Provider
      value={{
        token,
        isAuthenticated,
        decodedToken,
        id,
        role,
        user,
        vteAssigned,
        agency,
        permissions,
        accounts,
        firstName,
        lastName,
        login,
        signup,
        logout,
        update
      }} {...props} />
  )
}

const useAuth = () => React.useContext(AuthContext);
export { useAuth, AuthProvider }