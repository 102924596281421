const TextRender = ({ text }) => {
    const mailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi;
    const ftpRegex = /((?:s?ftps?:\/\/)(?:[^@\s]+)+@?(?:[^\?\s\:]+)?(?:\:(?:[0-9]+))?(?:\?(?:.+))?)/gi;
    const urlRegex =
        /((?:https?:\/\/)?(?:(?:[a-z0-9]?(?:[a-z0-9\-]{1,61}[a-z0-9])?\.[^\.|\s])+[a-z\.]*[a-z]+|(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3})(?::\d{1,5})*[a-z0-9.,_\/~#&=;%+?\-\\(\\)]*)/gi;

    const renderText = () => {
        return text.split(mailRegex).map((word, index) => {
            const mailMatch = word.match(mailRegex);
            if (mailMatch) {
                const url = mailMatch[0];
                return (
                    <a key={index} href={`mailto:${url}`}>
                        {url}
                    </a>
                );
            }
            return word.split(ftpRegex).map((ftpSplit, index) => {
                if (ftpSplit === undefined) return;
                const ftpMatch = ftpSplit.match(ftpRegex);
                if (ftpMatch) {
                    const url = ftpMatch[0];
                    return (
                        <a key={index} href={url}>
                            {url}
                        </a>
                    );
                }
                return ftpSplit.split(urlRegex).map((urlSplit, index) => {
                    const urlMatch = urlSplit.match(urlRegex);
                    if (urlMatch) {
                        const url = urlMatch[0];
                        return (
                            <a key={index} href={url} target="_blank">
                                {url}
                            </a>
                        )
                    }
                    return urlSplit
                })
            });
        });
    };

    return (
        <p className="text-normal" style={{
            whiteSpace: 'pre-wrap'
        }}>
            {renderText()}
        </p>
    )
};

export default TextRender;