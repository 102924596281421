import { AuthProvider } from "./auth.context";
import { ExportProvider } from "./export-provider";
import { ToastifyProvider } from "./toast.context";


export const AppProviders = ({ children }) => {
  return (
    <AuthProvider>
      <ExportProvider>
        <ToastifyProvider>
        {children}
        </ToastifyProvider>
      </ExportProvider>
    </AuthProvider>
  );
};
