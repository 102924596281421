import { createContext, useContext } from "react";
import { ToastContainer, toast } from 'react-toastify';

const ToastifyContext = createContext();

export function useToastifyContext() {
    const context = useContext(ToastifyContext);
    if (context === undefined) {
        throw new Error('ToastifyContext must be used within ToastifyProvider');
    }
    return context;
}

export function ToastifyProvider({ children }) {

    const defaultOptions = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        newestOnTop: false,
        closeOnClick: false,
        rtl: false,
        pauseOnFocusLoss: false,
        draggable: false,
        pauseOnHover: false,
        theme: "light",
    }

    const notify = (message, specificOptions) => toast(message || '', { ...defaultOptions, ...specificOptions });
    const notifySuccess = (message, specificOptions) => toast.success(message || '', { ...defaultOptions, ...specificOptions });
    const notifyError = (message, specificOptions) => toast.error(message || '', { ...defaultOptions, ...specificOptions });

    return (
        <ToastifyContext.Provider value={{ notify, notifyError, notifySuccess }}>
            <ToastContainer></ToastContainer>
            {children}
        </ToastifyContext.Provider>
    )
}