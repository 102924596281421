import React, { useState } from "react";
import { Row, Col } from "reactstrap";

import { httpPost, request } from "../../functions/request";
import "./help-request.scss";
import { useAuth } from "../../context/auth.context";
import { useToastifyContext } from "../../context/toast.context";

const HelpRequest = ({ setShowHelpReq }) => {
  const { token, agency } = useAuth();
  const {notifyError, notifySuccess} = useToastifyContext(); 

  const [help, setHelp] = useState("");
  const [object, setObject] = useState("");
  const [message, setMessage] = useState("");
  const [btnClass, setBtnClass] = useState("btn btn-secondary");
  const [btnText, setBtnText] = useState("Invia Richiesta");

  const sendMail = async (e) => {
    e.preventDefault();

    setBtnClass("btn btn-secondary disabled");
    setBtnText("Invio del messaggio in corso...");

    const formData = new FormData();
    formData.append("help", help);
    formData.append("object", object);
    formData.append("message", message);
    // for (let i = 0; i < e.target[3].files.length; i++) {
    // formData.append("attachments", e.target[3].files[i]);
    // }

    const data = await httpPost(
      "/users/assistence/help",
      {
        help,
        object,
        agency,
        message,
      },
      token
    );

    if (!data.ok) {
      setBtnClass("btn btn-danger disabled");
      setBtnText(data.result);

      notifyError((data.result ? data.result : "Messaggio non inviato, riprovare"), {autoClose: 5000});

      return setTimeout(() => {
        setBtnClass("btn btn-secondary");
        setBtnText("Invia Richiesta");
      }, 1000);
    }

    setBtnClass("btn btn-success disabled");
    setBtnText(data.results);

    notifySuccess((data.result ? data.result : "Messaggio inviato con succeso!"), {autoClose: 5000});

    return setTimeout(() => {
      setShowHelpReq(false);
    }, 1000);
  };

  const checkSizeFile = (e) => {
    const files = e.target.files;

    if (files.length > 5) {
      return;
    }

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > 15000000) {
        notifyError("File troppo grande! Massimo 15mb per file.");
        document.getElementById("attachments").value = "";
      }
    }
  };

  return (
    <div className="help-request-wrapper">
      <div className="help-request">
        <div className="help-request-top">
          <h2>Richiesta Supporto</h2>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setShowHelpReq(false);
            }}
          >
            &times;
          </a>
        </div>

        <hr />

        <form onSubmit={sendMail}>
          <Row>
            <Col md="6">
              <label htmlFor="help">Di cosa hai bisogno?</label>
              <select
                className="form-control"
                id="help"
                defaultValue={help}
                onChange={(e) => setHelp(e.target.value)}
              >
                <option value="">Seleziona un opzione</option>
                <option value="Problemi con un prodotto">
                  Problemi con un prodotto
                </option>
                <option value="Modifica dell'anagrafica">
                  Modifica dell'anagrafica
                </option>
                <option value="Richiesta di assistenza generica">
                  Richiesta di assistenza generica
                </option>
                <option value="Altro">Altro</option>
              </select>
            </Col>
            <Col md="6">
              <label htmlFor="obj">Oggetto</label>
              <input
                className="form-control"
                type="text"
                id="obj"
                defaultValue={object}
                onChange={(e) => setObject(e.target.value)}
              />
            </Col>
          </Row>

          <label htmlrFor="msg">Messaggio</label>
          <textarea
            className="form-control"
            name="msg"
            id="msg"
            defaultValue={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>

          <hr />
          
          <input
            type="file"
            name="attachments"
            id="attachments"
            onChange={checkSizeFile}
          />
          <label htmlrFor="attachments">Limite file 15MB &mdash; specificare link in caso di allegati con dimensione superiore</label>

          <hr />

          <input type="submit" className={btnClass} value={btnText} />
        </form>
      </div>
    </div>
  );
};

export default HelpRequest;
